import { RouteComponentProps } from 'react-router-dom';
import { UploadFile as IUploadFile } from 'antd/lib/upload/interface';

import { BenefitRoleName, AssignActions } from 'features/benefit/models/benefitModels';
import { FileData, NoteFileData } from 'features/upload/models/uploadModels';

export enum EditContractPaths {
    ContractDetails = '/project-details',
    ContractForm = '/project-form',
    Benefits = '/benefits-list',
    BenefitsFilter = '/benefits-filter',
    Invitations = '/invitations',
    ExpiredInvitations = '/expired-invitations',
    Filters = '/edit-filters',
    ContractFilter = '/edit-project-filter',
    Notes = '/project-notes',
}

export const contractType = [
    { name: 'Goods' },
    { name: 'Services' },
    { name: 'Works' },
    { name: 'Consultancy' },
];

export const budgetType = [
    { name: 'Capital' },
    { name: 'Grant' },
    { name: 'Revenue' },
    { name: 'Other' },
];

export const routeToMarket = [
    { name: 'Framework - Direct Award' },
    { name: 'Framework - Mini Competition' },
    { name: 'Framework - Other' },
    { name: 'Quotation - Low Value' },
    { name: 'Sole Supplier Negotiation' },
    { name: 'Tendered - Regulated Value' },
    { name: 'Tendered - High Value' },
    { name: 'Dynamic Purchasing System' },
];

export const benefitMethod = [
    { name: 'Evaluated' },
    { name: 'Mandatory' },
    { name: 'Voluntary' },
    { name: 'None' },
];

export const status = [
    { name: 'In Development' },
    { name: 'Tendered' },
    { name: 'Abandoned' },
    { name: 'Awarded' },
    { name: 'On Hold' },
    { name: 'In Progress' },
    { name: 'Completed' },
    { name: 'Incomplete' },
];

export const RAG = [{ name: 'Red' }, { name: 'Amber' }, { name: 'Green' }];

export interface RouteParams {
    id: string;
}

export type ContractDetailsRouteParams = RouteComponentProps<RouteParams>;

export interface Filters {
    archived: boolean;
    id: number;
    name: string;
    organisation: number;
}

export enum FilterType {
    CONTRACT_CATEGORIES = 'contractCategories',
    BENEFIT_CATEGORIES = 'benefitCategories',
    LOCATIONS = 'locations',
    DEPARTMENTS = 'departments',
    CONTRACT_PRIORITIES = 'contractPriorities',
    BENEFIT_PRIORITIES = 'benefitPriorities',
}

export interface CreateFilterRequest {
    name: string;
    filterType: FilterType;
}

export interface EditFilterRequest extends CreateFilterRequest {
    filterId: number;
}

export interface ArchiveFilterRequest extends EditFilterRequest {
    archived: boolean;
}

export interface ArchiveFilterResponse extends CreateFilterRequest {
    id: number;
    archived: boolean;
}

export interface ContractPrioritiesRequest {
    priority: {
        id: number;
        name: string;
    };
    value: number;
    name: string;
}

export interface ContractPriorities extends ContractPrioritiesRequest {
    id: number;
    priorityName: string;
}

export interface AddContractPriorityRequest {
    contractId: number;
    priority: ContractPrioritiesRequest;
}

export interface EditContractPriorityRequest {
    contractId: number;
    priorityId: number;
    value?: number;
}

export interface Contract {
    budget: string;
    routeToMarket: string;
    routeToMarketName: string;
    category: number;
    categoryName: string;
    contractType: string;
    department: number;
    departmentName: string;
    description: string;
    endDate: string;
    id: number;
    location: number;
    locationName: string;
    organisation: number;
    portfolioFilter: number;
    portfolioFilterName: string;
    published: boolean;
    referenceNumber?: string;
    startDate: string;
    status: string;
    title: string;
    value: number;
    priorities: ContractPriorities | string[];
    benefitValueRag: string;
    benefitPointsRag: string;
    benefitEmissionSavingsRag: string;
    benefitDeliveredNumberRag: string;
    benefitValue: number;
    benefitPoints: number;
    benefitEmissionSavings: number;
    benefitDeliveredNumber: number;
    targetBenefitValue?: number;
    targetBenefitPoints?: number;
    targetBenefitEmissionSavings?: number;
    targetBenefitDeliveryNumber?: number;
    frameworkContract: number;
    frameworkContractName: string;
    executive: string;
    contractFiles?: FileData[];
    awardedOn?: string;
}

export const defaultContract = {
    category: -1,
    contractType: '',
    department: -1,
    portfolioFilterName: '',
    description: '',
    endDate: '',
    id: -1,
    location: -1,
    portfolioFilter: -1,
    referenceNumber: '',
    startDate: '',
    status: '',
    title: '',
    value: -1,
    contractFiles: [],
};

export enum ContractRole {
    CONTRACT_MANAGER = 'CONTRACT_MANAGER',
    SUPPLIER = 'SUPPLIER_EXECUTIVE',
}

export interface ContractRoleData {
    contract: number;
    createdAt: string;
    grantedBy: number;
    id: number;
    organisation: number;
    role: ContractRole;
    user: number;
    userFullname?: string;
    organisationName?: string;
}

export interface OrganisationRole {
    user: number;
    name: string;
    contract: number;
    createdAt: string;
    grantedBy: number;
    id: number;
    organisation?: number;
    role: ContractRole;
}

export interface FilterContract extends Contract {
    path: string;
    valueMin: number;
    valueMax: number;
    minStartDate: string;
    maxStartDate: string;
    minEndDate: string;
    maxEndDate: string;
    contractDateRangeBefore: string;
    contractDateRangeAfter: string;
    supplierName: string;
    priority: string;
    priorities: string[];
    minAwardedDate: string;
    maxAwardedDate: string;
}

export interface ContractWithRAG {
    id: number;
    referenceNumber: string;
    title: string;
    benefitValueRag: string;
    benefitPointsRag: string;
    benefitEmissionSavingsRag: string;
    benefitDeliveredNumberRag: string;
    deliveredBenefitCount: number;
    deliveredValue: number;
    deliveredPoints: number;
    deliveredEmissionSavings: number;
    endDate: string;
    status: string;
}

export interface ContractRequest {
    contracts: Contract[];
    myContracts: Contract[];
    path: string;
}

export interface ContractRequestData {
    contracts: Contract[];
    categories: Filters[];
    departments: Filters[];
    locations: Filters[];
    portfolioFilters: Filters[];
    priorities: Filters[];
}

export interface ContractPriorityDashboardItem {
    id: number;
    name: string;
    deliveredPriorityValue: number;
    contractIds: number[];
}

export interface Detail {
    name: string;
    value: string;
    priorities?: DetailPriorities[];
    icon?: string;
}

export interface DetailPriorities {
    id: number;
    priority: number;
    value: number;
    priorityName: string;
}

export interface AddContractRoleRequest {
    role: ContractRole;
    roleId: number;
    contractId: number;
}

export interface DeleteContractRoleRequest {
    contractId: number;
    roleId: number | string;
}

export enum ContractFilterRole {
    Manager = 'manager',
    Supplier = 'supplier',
}

export interface GetContractRequest {
    organisationId?: number;
    contractId: number;
}

export interface PortfolioFilters {
    portfolioFilters: Filters[];
}

export interface RoleInvitation {
    id: number;
    email: string;
    organisation: number;
    firstName: string;
    lastName: string;
    telephone: string;
    userExists: boolean;
    awaitingRoles: BenefitRolePair[];
    active: boolean;
}

export interface BenefitRolePair {
    id: number;
    benefit: number;
    referenceNumber?: string;
    executive?: string;
    role: BenefitRoleName;
    contract: number;
}

export interface ToggleMultipleEmployeesContractRoleRequest {
    userIds: number[];
    contractIds: number[];
    action: AssignActions;
}

export interface ContractNoteData {
    contract: number;
    createdAt: string;
    updatedAt: string;
    id: number;
    organisation: number;
    authoredBy: string;
    note: string;
    files: NoteFileData[];
}

export interface CreateContractNoteRequest {
    contract: number;
    organisation: number;
    file?: File[];
    note?: string;
}

export interface DeleteContractNoteRequest {
    contract: number;
    id: number;
}

export interface EditContractNoteRequest {
    contract: number;
    id: number;
    note: string;
}

export interface DeleteContractNoteFileRequest {
    contract: number;
    id: number;
}

export interface DeleteContractFileRequest {
    contract: number;
    id: number;
}

export interface AddContractNoteFileRequest {
    contract: number;
    file: IUploadFile;
    note: number;
}

export interface AddContractFileRequest {
    contract?: number;
    file: FileData;
}
