import { Button, Collapse, Form, Modal, Tooltip } from 'antd';
import { DeleteOutlined, ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { ComponentType } from 'react';
import { orderBy } from 'lodash';
import styled from 'styled-components';

import {
    BenefitQuestionnaireType,
    benefitQuestionnairesTypesNames,
    QuestionnaireQuestion,
} from 'features/benefit/models/questionnaireModels';
import { Antd3Icon } from 'common/components/deprecated/antd3';
import { uniqueBenefitTooltips } from 'common/tooltips/uniqueBenefitTooltips';

import { Question } from './Question';

const ClearButton = styled(Button)`
    margin-top: 25px;
`;

type Props = {
    type: BenefitQuestionnaireType;
    isDisabled?: boolean;
    IsDisabledInfo?: ComponentType;
    isClearable?: boolean;
    isEditMode?: boolean;
};

export const QuestionnaireTemplate = ({
    type,
    isDisabled,
    IsDisabledInfo,
    isClearable,
    isEditMode,
}: Props) => {
    const form = Form.useFormInstance();

    const { confirm } = Modal;
    const updateQuestionIndex = (name: number, offset: 1 | -1): void => {
        const questions = form.getFieldValue([type]) as QuestionnaireQuestion[];

        if (!questions[name + offset]) {
            return;
        }

        questions[name + offset].index = name;
        questions[name].index = name + offset;
        questions.splice(name, 1, questions.splice(name + offset, 1, questions[name])[0]);
        form.setFieldsValue({ [type]: questions });
    };
    const questions = form.getFieldValue([type]) as QuestionnaireQuestion[];
    const removeQuestion = (name: number) => {
        const questions = form.getFieldValue([type]) as QuestionnaireQuestion[];

        questions.forEach((question) => {
            if (question.index > name) {
                question.index -= 1;
            }
        });

        questions.splice(name, 1);
        form.setFieldsValue({ [type]: questions.length ? questions : undefined });
    };

    const clearQuestions = () => {
        form.setFieldsValue({ [type]: isEditMode ? [] : null });
    };

    const showDeleteConfirm = () => {
        confirm({
            title: 'Are you sure delete all questions from this questionnaire?',
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                clearQuestions();
            },
        });
    };

    return (
        <Form.Item>
            <Collapse>
                <Collapse.Panel key="0" header={benefitQuestionnairesTypesNames[type]} forceRender>
                    {isDisabled && IsDisabledInfo && <IsDisabledInfo />}
                    <Form.List name={type}>
                        {(fields, { add }) => (
                            <>
                                {orderBy(fields).map(
                                    (field) =>
                                        field && (
                                            <Question
                                                key={field.key}
                                                questionnaireType={type}
                                                name={field.name}
                                                onUpdateIndex={updateQuestionIndex}
                                                onRemove={removeQuestion}
                                                disabled={isDisabled}
                                            />
                                        ),
                                )}
                                <Button
                                    onClick={() => add({ index: fields.length })}
                                    block
                                    icon={<PlusOutlined />}
                                    disabled={isDisabled}
                                >
                                    Add question
                                    <Tooltip title={uniqueBenefitTooltips.QuestionnaireNewQuestion}>
                                        <Antd3Icon type="info-circle-o" />
                                    </Tooltip>
                                </Button>
                                {isClearable && (
                                    <ClearButton
                                        onClick={showDeleteConfirm}
                                        icon={<DeleteOutlined />}
                                        block
                                        danger
                                    >
                                        Clear questions
                                    </ClearButton>
                                )}
                            </>
                        )}
                    </Form.List>
                </Collapse.Panel>
            </Collapse>
        </Form.Item>
    );
};
