import { Form, Tag } from 'antd';
import { Input } from 'common/components';
import { actionPropsByAction } from 'features/upload/helpers';
import * as S from '../shared/styles';
import { BenefitChangeSuggestionFormItemValue } from 'features/upload/models/uploadModels';
import { useEffect, useState } from 'react';

type Props = {
    name: string[];
    defaultValue: number | undefined;
};

export const BenefitValueSuggestionCard = ({ name, defaultValue }: Props) => {
    const benefitValue: BenefitChangeSuggestionFormItemValue | undefined = Form.useWatch([
        'benefitChangeSuggestions',
        'benefitValue',
    ]);
    const [action, setAction] = useState('');
    const [isUpdated, setIsUpdated] = useState<boolean>(false);

    useEffect(() => {
        if (!benefitValue) {
            setAction('');
            setIsUpdated(false);
        } else if (benefitValue && benefitValue != defaultValue) {
            setIsUpdated(true);
            setAction(defaultValue ? 'update' : 'create');
        } else {
            setAction('');
            setIsUpdated(false);
        }
    }, [benefitValue]);

    const actionProps = action && actionPropsByAction[action];
    const displayChange = actionProps && (action !== 'update' || isUpdated);
    return (
        <S.SuggestionWrapper color={actionProps.color}>
            {displayChange && (
                <S.ChangeInfo>
                    <Tag color={actionProps.color}>
                        {actionProps.label}
                        {isUpdated && (
                            <span>
                                {action === 'update' && `, previous Value: "${defaultValue}"`}
                            </span>
                        )}
                    </Tag>
                </S.ChangeInfo>
            )}
            <Form.Item name={name}>
                <Input name={name} type="number" label={'Benefit Value'} required={false} />
            </Form.Item>
        </S.SuggestionWrapper>
    );
};
