import { Form, Input, Tag } from 'antd';

import { actionPropsByAction } from 'features/upload/helpers';

import * as S from 'features/upload/components/BenefitChangeSuggestionsFormItem/shared/styles';

type Props = {
    name: string;
    contractId?: number;
    benefitId?: number;
    benefitLabel?: string;
};

export const BenefitLabelSuggestionAcceptanceCard = ({ name, benefitLabel }: Props) => {
    const labelSuggestion = Form.useWatch(name);
    const isUpdated = labelSuggestion !== benefitLabel;
    const action = isUpdated && !benefitLabel ? 'create' : 'update';
    const actionProps = actionPropsByAction[action];

    return (
        <S.SuggestionWrapper color={actionProps.color}>
            <S.ChangeInfo>
                {isUpdated && (
                    <Tag color={actionProps.color}>
                        {actionProps.label}
                        {
                            <span>
                                {action === 'update' && `, Current Benefit Label: ${benefitLabel}`}
                            </span>
                        }
                    </Tag>
                )}
            </S.ChangeInfo>
            <Form.Item name={name} label="Benefit Label">
                <Input name={name} type="text" disabled />
            </Form.Item>
        </S.SuggestionWrapper>
    );
};
