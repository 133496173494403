import { Form, Tag } from 'antd';
import { Input } from 'common/components';
import { actionPropsByAction } from 'features/upload/helpers';
import { useEffect, useState } from 'react';

import * as S from '../shared/styles';
import { BenefitChangeSuggestionFormItemLabel } from 'features/upload/models/uploadModels';

type Props = {
    name: string[];
    defaultValue: string | undefined;
};

export const BenefitLabelSuggestionCard = ({ name, defaultValue }: Props) => {
    const benefitLabel: BenefitChangeSuggestionFormItemLabel | undefined = Form.useWatch([
        'benefitChangeSuggestions',
        'benefitLabel',
    ]);
    const [action, setAction] = useState('');
    const [isUpdated, setIsUpdated] = useState<boolean>(false);

    useEffect(() => {
        if (!benefitLabel) {
            setAction('');
            setIsUpdated(false);
        } else if (benefitLabel && benefitLabel !== defaultValue) {
            setIsUpdated(true);
            setAction(defaultValue ? 'update' : 'create');
        } else {
            setAction('');
            setIsUpdated(false);
        }
    }, [benefitLabel]);

    const actionProps = action && actionPropsByAction[action];
    const displayChange = actionProps && (action !== 'update' || isUpdated);
    return (
        <S.SuggestionWrapper color={actionProps.color}>
            {displayChange && (
                <S.ChangeInfo>
                    <Tag color={actionProps.color}>
                        {actionProps.label}
                        {isUpdated && defaultValue && (
                            <span>
                                {action === 'update' && `, previous Label: "${defaultValue}"`}
                            </span>
                        )}
                    </Tag>
                </S.ChangeInfo>
            )}
            <Form.Item>
                <Input name={name} type="text" label={'Benefit Label'} required={false} />
            </Form.Item>
        </S.SuggestionWrapper>
    );
};
