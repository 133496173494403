import { useParams } from 'react-router-dom';
import moment from 'moment';
import { Select } from 'antd';
import { orderBy } from 'lodash';

import {
    Benefit,
    DeliveryDate,
    FetchedBenefitPriority,
} from 'features/benefit/models/benefitModels';
import { colors } from 'theme/variables';
import { dateFormat } from 'config/config';

import {
    BenefitChangeSuggestionFormItemLabel,
    BenefitChangeSuggestionFormItemLocation,
    BenefitChangeSuggestionFormItemValue,
    BenefitChangeSuggestions,
    BenefitChangeSuggestionsBenefitLocationAction,
    BenefitChangeSuggestionsBenefitPriorityAction,
    BenefitChangeSuggestionsDeliveryDateAction,
    BenefitChangeSuggestionsFormItemBenefitPriority,
    BenefitChangeSuggestionsFormItemDeliveryDate,
} from './models/uploadModels';
import { BenefitChangeSuggestionsFormValues } from './components/BenefitChangeSuggestionsFormItem/BenefitChangeSuggestionsFormItem';

export const useUploadParams = () => {
    const { organisationId, contractId, benefitId } = useParams<{
        organisationId: string;
        contractId: string;
        benefitId: string;
    }>();

    return {
        organisationId: parseInt(organisationId, 10),
        contractId: parseInt(contractId, 10),
        benefitId: parseInt(benefitId, 10),
    };
};

export const formatDeliveryDatesToSelectOptions = (deliveryDates: DeliveryDate[]): JSX.Element[] =>
    deliveryDates.map((deliveryDate) => (
        <Select.Option value={deliveryDate.id}>{`${deliveryDate.title}: ${moment(
            deliveryDate.date,
        ).format(dateFormat)}`}</Select.Option>
    ));

type ActionProps = { color: string; label: string; approveLabel: string };

export const actionPropsByAction: Record<
    | BenefitChangeSuggestionsDeliveryDateAction
    | BenefitChangeSuggestionsBenefitPriorityAction
    | BenefitChangeSuggestionsBenefitLocationAction,
    ActionProps
> = {
    create: {
        color: colors.green,
        label: 'Add new',
        approveLabel: 'Add to Benefit',
    },
    update: {
        color: colors.yellow,
        label: 'Update',
        approveLabel: 'Update',
    },
    delete: {
        color: colors.red,
        label: 'Remove',
        approveLabel: 'Remove from Benefit',
    },
};

export const getBenefitChangeSuggestionsInitialFormValues = (
    benefit: Benefit,
    benefitPriorities: FetchedBenefitPriority[],
    suggestions?: BenefitChangeSuggestions,
): BenefitChangeSuggestionsFormValues => {
    let deliveryDates: BenefitChangeSuggestionsFormItemDeliveryDate[];
    if (benefit?.deliveryDates.length) {
        deliveryDates = benefit?.deliveryDates.map((deliveryDate) => ({
            id: deliveryDate.id,
            isFinal: deliveryDate.isFinal,
            isStart: deliveryDate.isStart,
            prevTitle: deliveryDate.title,
            title: deliveryDate.title,
            prevDate: moment(deliveryDate.date),
            date: moment(deliveryDate.date),
            action: 'update',
            needsUserAction: deliveryDate.needsUserAction,
        }));
    }

    suggestions?.deliveryDates?.forEach((suggestion) => {
        switch (suggestion.action) {
            case 'create':
                deliveryDates.push({
                    action: 'create',
                    isFinal: false,
                    isStart: false,
                    title: suggestion.title,
                    date: moment(suggestion.date),
                    needsUserAction: true,
                });
                break;
            case 'delete':
                deliveryDates = deliveryDates.map((deliveryDate) =>
                    deliveryDate.id === suggestion.id
                        ? {
                              ...deliveryDate,
                              action: 'delete',
                          }
                        : deliveryDate,
                );
                break;
            case 'update':
                deliveryDates = deliveryDates.map((deliveryDate) =>
                    deliveryDate.id === suggestion.id
                        ? {
                              ...deliveryDate,
                              action: 'update',
                              prevTitle: deliveryDate.title,
                              title: suggestion.title,
                              prevDate: moment(deliveryDate.date),
                              date: moment(suggestion.date),
                          }
                        : deliveryDate,
                );
                break;
            default:
        }
    });

    let priorities: BenefitChangeSuggestionsFormItemBenefitPriority[] = benefit?.priorities?.map(
        (benefitPriority) => ({
            id: benefitPriority.id,
            priority: benefitPriority?.priority?.id,
            name: benefitPriority.name || benefitPriority.priority?.name,
            prevValue: benefitPriority.value,
            value: benefitPriority.value,
            action: 'update',
        }),
    );
    suggestions?.benefitPriorities?.forEach((suggestion) => {
        if (suggestion?.id && priorities.find((priority) => priority.id === suggestion.id)) {
            priorities = priorities.map((priority) =>
                priority.id === suggestion.id
                    ? {
                          ...priority,
                          value: suggestion.value,
                      }
                    : priority,
            );
        } else {
            priorities.push({ ...suggestion, action: 'create' });
        }
    });

    const benefitLocation: number = benefit?.location;
    const benefitValue: number | undefined = suggestions?.benefitValue;
    const benefitLabel: string | undefined = suggestions?.benefitLabel;
    const location: BenefitChangeSuggestionFormItemLocation = {
        prevLocation: suggestions?.benefitLocation ? suggestions?.benefitLocation : benefitLocation,
        location: suggestions?.benefitLocation,
    };

    return {
        deliveryDates: orderBy(deliveryDates, (deliveryDate) => deliveryDate.date),
        benefitPriorities: priorities,
        benefitLocation: location,
        benefitValue,
        benefitLabel,
    };
};
export const formatDate = (date: Date): string => moment(date).format(dateFormat);
