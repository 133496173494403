import { Menu, Button } from 'antd';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import posthog from 'posthog-js';
import { orderBy } from 'lodash';
import { LinkOutlined, LogoutOutlined } from '@ant-design/icons';
import { getMenuRoutes } from 'common/helpers/getMenuRoutes';
import {
    getOrganisationCurrentSubscription,
    isAdmin,
    isTenant,
} from 'features/user/selectors/userSelectors';
import { SENTRY_ENVIRONMENT } from 'config/env';
import { logOutRequest } from 'features/auth/actions/authActions';
import { NativeButtonProps } from 'antd/lib/button/button';
import React from 'react';
import { Notification, showNotification } from 'common/helpers/notifications';
import { EditPaths, SupplierEditPaths } from 'features/edit/models/editModel';
const LinkLabel = styled.span`
    margin-left: 16px;
`;

type Props = {
    onLinkClick?: () => void;
};

const StyledButton = styled((props: NativeButtonProps) => <Button {...props} />)`
    display: block;
    margin: 25px auto 0 auto;
`;

export const MenuContent = ({ onLinkClick }: Props) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const isUserAdmin = useSelector(isAdmin);
    const isUserTenant = useSelector(isTenant);
    const subscription = useSelector(getOrganisationCurrentSubscription);

    const isExpired = subscription?.status === 'EXPIRED';
    const menuRoutes = getMenuRoutes(isUserTenant, isUserAdmin, isExpired);
    const routePrefix = location.pathname.split('/')[1];

    const hasShownExpiredNotification = React.useRef(false);

    const handleLogout = () => {
        if (SENTRY_ENVIRONMENT === 'frontend-production') {
            posthog.reset(true);
        }
        dispatch(logOutRequest());
        if (isExpired) {
            history.push('/sign-in');
        }
    };

    const redirectToRenewSubscriptionView = () => {
        if (isUserTenant) {
            history.push(`${EditPaths.Subscriptions}`);
            return;
        }
        history.push(`${SupplierEditPaths.Users}`);
    };

    React.useEffect(() => {
        if (isExpired && !hasShownExpiredNotification.current) {
            hasShownExpiredNotification.current = true;
            showNotification({
                type: Notification.Warning,
                duration: 100000,
                text: 'Your subscription has expired. Please renew it to have access to all features.',
            });
        }
    }, []);

    return (
        <Menu mode="inline" theme="dark" selectedKeys={[routePrefix]}>
            {orderBy(menuRoutes, (route) => route.order).map((route) => (
                <Menu.Item key={route.key}>
                    <Link to={route.path} onClick={onLinkClick}>
                        {route.label}
                    </Link>
                </Menu.Item>
            ))}
            <Menu.Item key="logout" onClick={handleLogout}>
                <LogoutOutlined />
                <LinkLabel>Logout</LinkLabel>
            </Menu.Item>
            {isUserAdmin && isExpired && (
                <StyledButton type="default" onClick={() => redirectToRenewSubscriptionView()}>
                    Upgrade Account
                </StyledButton>
            )}
        </Menu>
    );
};
