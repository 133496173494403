import { Button, Form, Input, InputNumber, Tag } from 'antd';
import { useDispatch } from 'react-redux';

import { actionPropsByAction } from 'features/upload/helpers';
import { BenefitChangeSuggestionsAcceptanceFormBenefitPriority } from 'features/upload/models/uploadModels';
import { addBenefitPriorityRequest, editBenefitPriorityRequest } from 'features/benefit/actions';
import * as S from 'features/upload/components/BenefitChangeSuggestionsFormItem/shared/styles';
import { values } from 'lodash';

type Props = {
    name: string;
    contractId?: number;
    benefitId?: number;
    benefitValue?: number;
};

export const BenefitValueSuggestionAcceptanceCard = ({ name, benefitValue }: Props) => {
    const valueSuggestion = Form.useWatch(name);
    const isUpdated = Math.round(valueSuggestion) !== Math.round(benefitValue);
    const action = isUpdated && !benefitValue ? 'create' : 'update';
    const actionProps = actionPropsByAction[action];

    return (
        <S.SuggestionWrapper color={actionProps.color}>
            <S.ChangeInfo>
                {isUpdated && (
                    <Tag color={actionProps.color}>
                        {actionProps.label}
                        {
                            <span>
                                {action === 'update' &&
                                    `, Current Benefit Value: ${
                                        benefitValue && Math.round(benefitValue)
                                    }`}
                            </span>
                        }
                    </Tag>
                )}
            </S.ChangeInfo>
            <Form.Item name={name} label="Benefit Value">
                <Input name={name} type="number" disabled />
            </Form.Item>
        </S.SuggestionWrapper>
    );
};
