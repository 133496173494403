import { Form, FormInstance, Tag } from 'antd';
import { NamePath } from 'antd/lib/form/interface';
import { AutocompleteSelect } from 'common/components';
import { HttpService } from 'common/services/HttpService';
import { organisationUrl } from 'config/config';
import { AutocompleteField } from 'features/pagination/models/paginationModel';
import { actionPropsByAction } from 'features/upload/helpers';
import { BenefitChangeSuggestionFormItemLocation } from 'features/upload/models/uploadModels';
import { useEffect, useState } from 'react';

import * as S from '../shared/styles';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
    getAutocompleteResults,
    getPaginationState,
} from 'features/pagination/selectors/paginationSelectors';
import { stat } from 'fs';

type Props = {
    onSelect: (location: number) => void;
    onDelete: () => void;
    name?: NamePath;
    defaultValue?: number;
};

export const BenefitLocationSuggestionCard = ({
    onSelect,
    name,
    onDelete,
    defaultValue,
}: Props) => {
    const benefitLocation: BenefitChangeSuggestionFormItemLocation | undefined = Form.useWatch([
        'benefitChangeSuggestions',
        'benefitLocation',
    ]);
    const locations = useSelector((state) =>
        getAutocompleteResults(state, AutocompleteField.Location),
    );
    const { organisationId } = useParams();

    const [action, setAction] = useState('');
    const [isUpdated, setIsUpdated] = useState<boolean>(false);

    useEffect(() => {
        if (!benefitLocation?.location) {
            setAction('');
            setIsUpdated(false);
        }
        if (benefitLocation?.location && benefitLocation?.location !== defaultValue) {
            setIsUpdated(true);
            setAction(defaultValue ? 'update' : 'create');
        } else {
            setIsUpdated(false);
            setAction('');
        }
    }, [benefitLocation]);
    const prevLocation = locations.find((location) => location.id === defaultValue);
    const actionProps = action && actionPropsByAction[action];
    const displayChange = actionProps && (action !== 'update' || isUpdated);

    return (
        <S.SuggestionWrapper
            key={benefitLocation?.location}
            color={displayChange ? actionProps.color : undefined}
        >
            {displayChange && (
                <S.ChangeInfo>
                    <Tag color={actionProps.color}>
                        {actionProps.label}
                        {isUpdated && (
                            <span>
                                {action === 'update' &&
                                    `, previous Location: "${prevLocation?.name}"`}
                            </span>
                        )}
                    </Tag>
                </S.ChangeInfo>
            )}
            <AutocompleteSelect
                key={benefitLocation?.location}
                name={name}
                initialValue={benefitLocation?.location}
                onSelect={onSelect}
                onDeselect={onDelete}
                label="Benefit Location"
                autocompleteField={AutocompleteField.Location}
                requestParams={{ organisationId }}
                notFoundContent="No locations found"
                required={false}
            />
        </S.SuggestionWrapper>
    );
};
